/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Material Dashboard 2 PRO React context
import forgeBlackLogo from "assets/forge/svgs/forge_black.svg";
import appStoreDownload from "assets/forge/svgs/appstore_download.svg";
import { useMaterialUIController } from "context";
import Carousel from "react-material-ui-carousel";
import { Avatar, Stack, useMediaQuery } from "@mui/material";
import { Star } from "@mui/icons-material";
import Review from "./review";
import theme from "assets/theme";

// Declaring props types for IllustrationLayout
interface Props {
  header?: ReactNode;
  title?: string;
  description?: string;
  testimonials?: boolean;
  children: ReactNode;
  illustration?: string;
}

function IllustrationLayout({
  header,
  title,
  description,
  testimonials,
  illustration,
  children,
}: Props): JSX.Element {
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main,
        }}
      >
        <Grid item xs={12} lg={6}>
          {testimonials
            ? <MDBox
              display={{ xs: "none", lg: "flex" }}
              style={{ backgroundSize: "70%", backgroundColor: "black", }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{
                backgroundImage: `url(${illustration})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center 24%",
              }}>
              <Carousel
                interval={10000}
                sx={{ width: "100%", margin: "32px 12%" }}
                height="100%"
              >
                <Review
                  review='"Forge was the key factor that allowed me to close our company’s biggest-ever deal. There’s no other way I would’ve been able to build trust with 20 new decision makers and key influencers in the time available. Forge is essential for getting big deals done."'
                  image="https://static.wixstatic.com/media/ee3a5b_f40e4631a3d1417bad2c80310c750a22~mv2.jpeg"
                  author="Shawn"
                  role="Director of Infrastructure Sales"
                />
                <Review
                  review='“Forge is a mindset coach; it ensures I practice the right behaviors when it comes to building relationships with my clients and network. By listening to my clients and recalling what’s important to them at the right time, I’m able to connect more deeply than I was doing so before.”'
                  image="https://static.wixstatic.com/media/ee3a5b_76d35a93f8404c2fbde3f9fb83a2e7d6~mv2.jpeg"
                  author="Holly"
                  role="Realtor"
                />
                <Review
                  review='“It’s the 21st-century equivalent of writing things down… Forge consolidates what I need to know to push a sales relationship forward, and reminds me to take appropriate steps to do it.”'
                  image="https://static.wixstatic.com/media/ee3a5b_5a521e8d9f514dc1982a3f18320e1501~mv2.jpeg"
                  author="Dominic"
                  role="Senior Director of Sales"
                />
                <Review
                  review='"Relationships are the foundation for doing business, and Forge enables relationships by remembering and preparing you with the important details of the people you meet with. I highly recommend using Forge every day.”'
                  image="https://static.wixstatic.com/media/ee3a5b_45b9bbde4cf7472e9ade9a9855e3112b~mv2.jpeg"
                  author="Geoff"
                  role="Global TelCo VP"
                />
                <Review
                  review='“I’m using Forge for a huge meeting that I have on Wednesday with a general – it’s been extremely helpful for me. I absolutely love it... it’s such an amazing product!”'
                  image="https://static.wixstatic.com/media/ee3a5b_5a0d351f34b147d28a0ea19ff02bccf1~mv2.jpeg"
                  author="Sara"
                  role="Founder & CEO"
                />
                <Review
                  review='“I want my clients to feel like I don’t work with anybody else, and Forge gives me ways to connect on a deeper level with them. I find communication is so easy, but now thanks to Forge I’m listening a little bit deeper than I was... this app means a lot to me because it is who I am, and it’s easy to work with something when it works well with me.”'
                  image="https://static.wixstatic.com/media/ee3a5b_73748a63bed74671a78d55242a3e059b~mv2.jpeg"
                  author="Stacy"
                  role="Realtor"
                />
                <Review
                  review='“Forge is the best mobile CRM experience I’ve ever seen, and I’ve seen them all. It understands what’s actually important in sales conversations.”'
                  image="https://static.wixstatic.com/media/ee3a5b_89dd2cb09382469a8101c2305db272dc~mv2.jpeg"
                  author="Dan"
                  role="Senior Director of Sales"
                />
                <Review
                  review='“I love this, it’s exactly what I’ve wanted and it’s who I am. Forge will be a powerful tool for our producers as well since their business is so strongly driven by their relationships. Forge is a game changer.”'
                  image="https://static.wixstatic.com/media/ee3a5b_35cf86c48cad4e9ea85f5d247b559aa2~mv2.jpeg"
                  author="Mike"
                  role="SVP and co-head of investment sales"
                />
                <Review
                  review={`"In building a new company, I've quickly learned that developing strong, human-centric relationships is a force multiplier. Forge takes it to another level by putting theory into practice. It makes the traditional methods of notes, calendar reminders, spreadsheets, and relying on memory feel archaic. Every founder (& human) should be using Forge."`}
                  image="https://storage.googleapis.com/forge-development.appspot.com/Media/39725d1d-9ff3-496d-90b2-21f7cb089c55-removebg-preview.png"
                  author="Braeden"
                  role="Cofounder & CEO"
                />
              </Carousel>
            </MDBox>
            : <MDBox
              display={{ xs: "none", lg: "flex" }}
              style={{ backgroundSize: "70%", backgroundColor: "black", }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{
                backgroundImage: `url(${illustration})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />}
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{
              background: "#E8F0FE",
              display: lg ? undefined : "none",
              width: "100vw",
              transform: `translateX(-${sm ? "4" : md ? "16.5" : "24.5"}%)`,
              padding: "16px 12%"
            }}>
            <MDTypography variant="h5" fontWeight="bold" textAlign="center" style={{ color: "black" }}>
              Download the Forge® App
            </MDTypography>
            <MDTypography variant="body2" textAlign="center">
              Enjoy seamless 2-way sync between desktop and mobile, so you can manage your relationships and tasks effortlessly, anytime, anywhere.
            </MDTypography>
            <img
              src={appStoreDownload}
              style={{ height: "56px", paddingTop: "8px", cursor: "pointer" }}
              alt="App Store Download"
              onClick={() => window.open("https://apps.apple.com/us/app/forge-business-relationships/id1582340845", "_blank")}
            />
          </MDBox>
          <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <MDBox py={3} px={3} textAlign="center">
              <img src={forgeBlackLogo} style={{ height: "30px", display: lg ? undefined : "none" }} alt="Apple" />
              {!header ? (
                <>
                  <MDBox mb={1} textAlign="center">
                    <MDTypography variant="h4" fontWeight="bold" style={{ color: "black" }}>
                      {title}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    {description}
                  </MDTypography>
                </>
              ) : (
                header
              )}
            </MDBox>
            <MDBox p={3}>{children}</MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

export default IllustrationLayout;
