import { forwardRef, useContext, useEffect, useState } from "react";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import firebase from "firebase/compat/app";
import { auth } from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { AuthContext } from "context";

// Image
import bgImage from "assets/forge/images/forge.png";
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { createUseStyles } from "react-jss";
import SignInButton from "../components/SignInButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const styles = createUseStyles({
  ssoButtons: {
    "& .firebaseui-idp-button": {
      background: "white !important",
      borderRadius: "8px"
    },
    "& .firebaseui-idp-text": {
      color: "#757575 !important"
    },
    "& .firebaseui-idp-icon": {
      filter: "brightness(0) !important"
    }
  },
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SignUp(): JSX.Element {
  // UI
  const classes = styles();

  // Context
  const { login, loggingIn, microsoftSso } = useContext(AuthContext);

  // State
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleClickOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);


  useEffect(() => {
    var ui = auth.AuthUI.getInstance() || new auth.AuthUI(firebase.auth());
    try {
      ui.start("#firebaseui-auth-container", {
        signInFlow: "popup",
        // signInSuccessUrl: "/home",
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, _) {
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect
            // automatically or whether we leave that to developer to handle.
            login();
            return false;
          },
          signInFailure: function (error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            console.log(JSON.stringify(error));
            return;
          },
          uiShown: function () {
            // The widget is rendered.
          },
        },
        signInOptions: [
          // List of OAuth providers supported.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: ['email', 'profile'],
          },
          "apple.com",
        ],
      });
    } catch (error) {
      console.warn(error);
    }
  });

  return (
    <IllustrationLayout
      title="Sign Up for Free"
      description="Get instant access to Forge's AI-powered CRM co-pilot and see how it can supercharge your relationships and revenue."
      illustration={bgImage}
      testimonials={true}
    >
      {loggingIn
        ? <img src={spinningSphere} style={{ width: "256px", margin: "0 auto", display: "block" }} alt="Loading..." />
        : <div>
          <div id="firebaseui-auth-container" className={classes.ssoButtons}></div>
          <SignInButton
            ssoOption="microsoft"
            cta="Sign in"
            onClick={() => microsoftSso()}
          />
        </div>
      }
      <MDBox mt={7} mb={4} textAlign="center">
        <MDTypography variant="button" color="text">
          Already have an account?{" "}
          <MDTypography
            component={Link}
            to="/sign-in"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Sign In
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="button"
        color="info"
        fontWeight="medium"
        textGradient
        onClick={handleClickOpen}
        textAlign="center"
        style={{ cursor: "pointer", width: "100%" }}
      >
        Why is there no email sign-up option?
      </MDTypography>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: "8px" }}
      >
        <DialogTitle style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>{"Why is there no email sign-up option?"}</DialogTitle>
        <DialogContent style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>
          <DialogContentText id="alert-dialog-slide-description" style={{ textAlign: "justify" }}>
            We prioritize security. Using trusted providers like Google, Apple,
            and Microsoft for Single Sign-On (SSO) means we don’t store passwords,
            and they offer features like multi-factor authentication for added protection.
            This helps keep your data safe and reduces spam or fake accounts.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </IllustrationLayout >
  );
}

export default SignUp;
