import {
  doc,
  collection,
  query,
  orderBy,
  updateDoc,
  Unsubscribe,
  onSnapshot,
  where,
  QuerySnapshot,
  DocumentData,
  Timestamp,
  DocumentReference,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { Milestone } from 'types/milestone';
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option";
import { firestoreDebounce } from "forge/core/utils/firestore";
import { ForgeEncryption } from "forge/core/services/encryption";

class ContactsFirestore {
  private user: User;
  private userProfileData: UserProfileData;
  private encryptionService: ForgeEncryption;

  constructor(
    user: User,
    userProfileData: UserProfileData,
    encryptionService: ForgeEncryption,
  ) {
    this.user = user;
    this.userProfileData = userProfileData;
    this.encryptionService = encryptionService;
    this.encryptionService = encryptionService;
  }

  getPersonalContactsLive = (onEvent: (contacts: any[]) => void): Unsubscribe => {
    const collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/contacts`),
      // collection(firestoreDb, `users/s52iBhT2HUYhsHyUF60bprNX8lf1/contacts`),
      // collection(firestoreDb, `users/7YZiqhhboeUnDkdJZ60oT4GrHTs1/contacts`),
      orderBy("name", "asc"),
      where("status", "==", "live"),
    );

    return firestoreDebounce(
      collectionRef,
      (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
        onEvent(
          snapshot.docs.map(doc => ({
            id: doc.id,
            isOrganizationContact: false,
            ...doc.data(),
          }))
        );
      }
    );
  }

  getOrganizationContactsLive = (onEvent: (contacts: any[]) => void): Unsubscribe => {
    if (this.userProfileData?.organization?.id) {
      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/contacts`),
        orderBy("name", "asc"),
        where("status", "==", "live"),
      );

      return firestoreDebounce(
        collectionRef,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
          onEvent(
            snapshot.docs.map(doc => ({
              id: doc.id,
              isOrganizationContact: true,
              ...doc.data(),
            }))
          );
        }
      );
    }

    return;
  }

  saveQuestionsVars = async (
    updatedAnswers: { [key: string]: any },
    contactRef: DocumentReference,
  ): Promise<void> => {
    try {
      const updatedVars = Object.entries(updatedAnswers).reduce((acc: any, [key, value]) => {
        acc[`questionsVars.${key}`] = value;
        acc[`questionsVarsCreatedBy.${key}`] = this.user.uid;
        return acc;
      }, {});

      await updateDoc(contactRef, updatedVars);
    } catch (error) {
      console.error('Error saving questions vars:', error);
      throw error;
    }
  }

  getLinkedInProfileOptionsLive = (
    contactRef: DocumentReference,
    onEvent: (profileOptions: LinkedInProfileOption[]) => void
  ): Unsubscribe => {
    const collectionRef = query(
      collection(firestoreDb, `${contactRef.path}/linkedInOptions`),
      orderBy("order")
    );

    return onSnapshot(collectionRef, async (snapshot) => {
      const profileOptions = snapshot.docs.map((doc) => (LinkedInProfileOption.fromMap(doc.data())));
      onEvent(profileOptions);
    });
  }

  getPersonalMilestonesLive = (
    contact: any,
    onEvent: (profileOptions: any[]) => void
  ): Unsubscribe => {
    const today = new Date();
    const oneYearFromNow = new Date();
    oneYearFromNow.setDate(today.getDate() + 365);

    const collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/milestones`),
      where("contactRefs", "array-contains", contact.ref),
      where('startDate', '>=', Timestamp.fromDate(today)),
      where('startDate', '<', Timestamp.fromDate(oneYearFromNow)),
      orderBy("startDate")
    );

    return firestoreDebounce(
      collectionRef,
      async (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
        const milestonesPromises = snapshot.docs.map(
          (doc) => (Milestone.fromMap(
            {
              ...doc.data(),
              isOrganizationMilestone: false,
            },
            this.encryptionService,
          ))
        );

        let decrypted = await Promise.allSettled(milestonesPromises);
        const milestones: Milestone[] = decrypted
          .filter((e: any) => e.status === "fulfilled")
          .map((e: any) => e.value);

        onEvent(milestones);
      }
    );
  }

  getOrganizationMilestonesLive = (
    contact: any,
    onEvent: (profileOptions: any[]) => void
  ): Unsubscribe => {
    if (this.userProfileData?.organization?.id) {
      const today = new Date();
      const oneYearFromNow = new Date();
      oneYearFromNow.setDate(today.getDate() + 365);

      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/milestones`),
        where("contactRefs", "array-contains", contact.ref),
        where('startDate', '>=', Timestamp.fromDate(today)),
        where('startDate', '<', Timestamp.fromDate(oneYearFromNow)),
        orderBy("startDate")
      );

      return firestoreDebounce(
        collectionRef,
        async (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
          const milestonesPromises = snapshot.docs.map(
            (doc) => (Milestone.fromMap(
              {
                ...doc.data(),
                isOrganizationMilestone: false,
              },
              this.encryptionService,
            ))
          );

          let decrypted = await Promise.allSettled(milestonesPromises);
          const milestones: Milestone[] = decrypted
            .filter((e: any) => e.status === "fulfilled")
            .map((e: any) => e.value);

          onEvent(milestones);
        }
      );
    }
    return;
  }
}

export default ContactsFirestore;