
import { firestoreDb } from 'firebase.init';
import { DocumentReference, Timestamp, doc } from 'firebase/firestore';
import { EncryptionResult, ForgeEncryption } from 'forge/core/services/encryption';
import { EncryptionConfig } from 'types/encryption-config';
import { SearchTerm } from "forge/knowledge/schemas/search-term";
import { KnowledgeType, knowledgeTypeStringMap } from './knowledge-type';
import { dateFromMap, documentReferenceFromMap } from 'forge/core/utils/schema-parsing';
import { Milestone } from 'types/milestone';

export class Knowledge {
    knowledgeCustomId?: string;
    addKnowledgeId?: string;
    ref?: DocumentReference;
    answer?: string;
    optionsSelected: SearchTerm[];
    category: string[];
    searchTerm: string[];
    entity: string[];
    label?: string;
    contact?: any;
    contactRef?: DocumentReference;
    options: SearchTerm[];
    type: KnowledgeType;
    visible: boolean;
    questionId?: string;
    answerFormat?: string;
    answers?: string[];
    subjects: string[];
    milestone?: Milestone;
    analyzing?: boolean;
    encrypted?: boolean;
    encrypter?: string;
    isOrganizationKnowledge: boolean;
    createdBy: DocumentReference;
    encryptedBy: DocumentReference;
    isPrivate: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor({
        knowledgeCustomId,
        addKnowledgeId,
        ref,
        answer,
        optionsSelected = [],
        category = [],
        searchTerm = [],
        entity = [],
        label,
        contact,
        contactRef,
        questionId,
        answerFormat,
        answers = [],
        subjects = [],
        milestone,
        options = [],
        type = KnowledgeType.user,
        visible = true,
        analyzing = false,
        encrypted = false,
        encrypter,
        isOrganizationKnowledge = false,
        createdBy,
        encryptedBy,
        isPrivate = false,
        createdAt,
        updatedAt,
    }: {
        knowledgeCustomId?: string;
        addKnowledgeId?: string;
        ref?: DocumentReference;
        answer?: string;
        optionsSelected?: SearchTerm[];
        category?: string[];
        searchTerm?: string[];
        entity?: string[];
        label?: string;
        contact?: any;
        contactRef: DocumentReference;
        questionId?: string;
        answerFormat?: string;
        answers?: string[];
        subjects?: string[];
        milestone?: Milestone;
        options?: SearchTerm[];
        type?: KnowledgeType;
        visible?: boolean;
        analyzing?: boolean;
        encrypted?: boolean;
        encrypter?: string;
        isOrganizationKnowledge?: boolean;
        createdBy: DocumentReference;
        encryptedBy: DocumentReference;
        isPrivate?: boolean;
        createdAt?: Date;
        updatedAt?: Date;
    }) {
        this.knowledgeCustomId = knowledgeCustomId;
        this.addKnowledgeId = addKnowledgeId;
        this.ref = ref;
        this.answer = answer;
        this.optionsSelected = optionsSelected;
        this.category = category;
        this.searchTerm = searchTerm;
        this.entity = entity;
        this.label = label;
        this.contact = contact;
        this.contactRef = contactRef;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.questionId = questionId;
        this.answerFormat = answerFormat;
        this.answers = answers;
        this.subjects = subjects ?? [];
        this.milestone = milestone;
        this.options = options;
        this.type = type;
        this.visible = visible;
        this.analyzing = analyzing;
        this.encrypted = encrypted;
        this.encrypter = encrypter;
        this.isOrganizationKnowledge = isOrganizationKnowledge;
        this.createdBy = createdBy;
        this.encryptedBy = encryptedBy;
        this.isPrivate = isPrivate;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    async toMap({
        toFirestore = false,
        encryptionConfig,
    }: {
        toFirestore?: boolean
        encryptionConfig?: EncryptionConfig;
    }): Promise<any> {
        const isEncrypted = encryptionConfig && encryptionConfig.encrypted;
        const encryptionResultAnswer: EncryptionResult = isEncrypted
            ? await encryptionConfig.service.encrypt(
                this.answer,
                encryptionConfig.encryptedForOrganization
                    ? encryptionConfig.organization?.sealdGroupId
                    : undefined,
            )
            : {
                result: this.answer,
                encrypter: "seald",
                encrypted: false,
            };

        const encryptionResultLabel: EncryptionResult = isEncrypted
            ? await encryptionConfig.service.encrypt(
                this.label,
                encryptionConfig.encryptedForOrganization
                    ? encryptionConfig.organization?.sealdGroupId
                    : undefined,
            )
            : {
                result: this.label,
                encrypter: "seald",
                encrypted: false,
            };

        const map = {
            knowledgeCustomId: this.knowledgeCustomId,
            addKnowledgeId: this.addKnowledgeId,
            answer: encryptionResultAnswer.result,
            optionsSelected: this.optionsSelected.map(e => e.toMap()),
            category: this.category,
            searchTerm: this.searchTerm,
            entity: this.entity,
            label: encryptionResultLabel.result,
            contactRef: toFirestore ? this.contactRef : this.contactRef?.path,
            ref: toFirestore ? this.ref : this.ref?.path,
            questionId: this.questionId,
            answerFormat: this.answerFormat,
            answers: this.answers,
            subjects: this.subjects,
            milestone: this.milestone
                ? await this.milestone.toMap({ toFirestore, encryptionConfig })
                : null,
            options: this.options.map(e => e.toMap()),
            plainOptions: this.options.map(e => e.searchTerm),
            plainEntities: [...new Set(this.options.map(e => e.entity))],
            encrypted: encryptionResultAnswer.encrypted,
            encrypter: encryptionResultAnswer.encrypter,
            type: this.type.toString(),
            visible: this.visible,
            isPrivate: false,
            isOrganizationKnowledge: this.isOrganizationKnowledge,
            createdBy: toFirestore ? this.createdBy : this.createdBy?.path,
            encryptedBy: toFirestore ? this.encryptedBy : this.encryptedBy?.path,
            createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
            updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.updatedAt?.valueOf(),
        };

        return map;
    }

    static async fromMap(
        map: any,
        encryptionService: ForgeEncryption,
        organizationId?: string,
    ): Promise<Knowledge> {
        try {
            let encryptedBy = documentReferenceFromMap(map.encryptedBy);

            let answer = (map.encrypted ?? false)
                ? await encryptionService.decrypt({
                    data: map.answer,
                    encrypter: map.encrypter,
                })
                : map.answer;

            let label = (map.encrypted ?? false)
                ? await encryptionService.decrypt({
                    data: map.label,
                    encrypter: map.encrypter,
                })
                : map.label;

            const type = Object.entries(knowledgeTypeStringMap).find(
                (entry) => entry[1] === map.type
            );

            const ref = documentReferenceFromMap(map.ref);

            return new Knowledge({
                knowledgeCustomId: map.knowledgeCustomId,
                addKnowledgeId: map.addKnowledgeId,
                answer: answer,
                optionsSelected: map.optionsSelected ? map.optionsSelected.map((e: any) => SearchTerm.fromMap(e)) : [],
                category: map.category ? (Array.isArray(map.category) ? map.category : [map.category]) : [],
                searchTerm: map.searchTerm ? (Array.isArray(map.searchTerm) ? map.searchTerm : [map.searchTerm]) : [],
                entity: map.entity ? (Array.isArray(map.entity) ? map.entity : [map.entity]) : [],
                label: label,
                ref: ref,
                contactRef: documentReferenceFromMap(map.contactRef),
                questionId: map.questionId,
                answerFormat: map.answerFormat,
                answers: map.answers ? Array.from(map.answers) : [],
                subjects: map.subjects ? Array.from(map.subjects) : [],
                milestone: map.milestone
                    ? await Milestone.fromMap(map.milestone, encryptionService, organizationId)
                    : null,
                options: map.options ? map.options.map((e: any) => SearchTerm.fromMap(e)) : [],
                type: type ? type[0] as KnowledgeType : KnowledgeType.user,
                visible: map.visible ?? true,
                encrypted: map.encrypted,
                encrypter: map.encrypter,
                isOrganizationKnowledge: map.isOrganizationKnowledge ?? false,
                createdBy: documentReferenceFromMap(map.createdBy),
                encryptedBy: encryptedBy,
                isPrivate: map.isPrivate ?? false,
                createdAt: dateFromMap(map.createdAt),
                updatedAt: dateFromMap(map.updatedAt),
            });
        } catch (error) {
            console.warn(error);
        }
    }
}