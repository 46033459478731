import { Add, Chat, ForwardToInboxOutlined, IosShare, Save } from "@mui/icons-material";
import { Stack } from "@mui/material";
import MDBox from "components/MDBox";

function ShareActions({
    handleEmailAction,
    handleSmsAction,
    handleShareAction,
    enableAdd = false,
    handleAddAction,
    emailActionBackground,
    smsActionBackground,
}: {
    handleEmailAction: () => void,
    handleSmsAction: () => void,
    handleShareAction: () => void,
    enableAdd?: boolean,
    handleAddAction?: () => void,
    emailActionBackground?: string,
    smsActionBackground?: string,
}) {

    return (
        <Stack
            direction="row"
            justifyContent="right"
            alignItems="right"
            spacing={0.7}>
            {!enableAdd && <MDBox
                variant="gradient"
                color={"white"}
                width="3rem"
                height="3rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
                style={{ cursor: "pointer", background: emailActionBackground ?? "cornflowerblue" }}
                onClick={handleEmailAction}
            >
                <ForwardToInboxOutlined />
            </MDBox>}
            {!enableAdd && <MDBox
                variant="gradient"
                bgColor={"white"}
                color={"white"}
                width="3rem"
                height="3rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
                style={{ cursor: "pointer", background: smsActionBackground ?? "cornflowerblue" }}
                onClick={handleSmsAction}
            >
                <Chat />
            </MDBox>}
            {handleShareAction && <MDBox
                variant="gradient"
                bgColor={"white"}
                color={"white"}
                width="3rem"
                height="3rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
                style={{ cursor: "pointer", background: "cornflowerblue" }}
                onClick={handleShareAction}
            >
                <IosShare />
            </MDBox>}
            {enableAdd && <MDBox
                variant="gradient"
                bgColor={"white"}
                color={"white"}
                width="3rem"
                height="3rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
                style={{ cursor: "pointer", background: "cornflowerblue" }}
                onClick={handleAddAction}
            >
                <Add fontSize="medium" />
            </MDBox>}
        </Stack>
    );
};

// Setting default values for the props of ShareActions
ShareActions.defaultProps = {
    handleEmailAction: null,
    handleSmsAction: null,
    handleShareAction: null,
};

export default ShareActions;