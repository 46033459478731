import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { ContactsContext } from "context";
// import { List, AutoSizer } from 'react-virtualized';

// MUI
import {
    Drawer,
    OutlinedInput,
    Stack,
    Tab,
    Tabs,
    useMediaQuery,
} from "@mui/material";
import { InfoOutlined, Search } from "@mui/icons-material";

// Material Dashboard
import MDBox from "components/MDBox";

// Services
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel";
import ContactCard from "../ContactCard";
import { Timestamp } from "firebase/firestore";
import AddContactCard from "../AddContactCard";
import MDTypography from "components/MDTypography";
import CreateContactDrawer from "forge/people/contacts/components/CreateContactDrawer";
import theme from "assets/theme";

function SelectContact({
    onContactSelected,
    onContactUnselected,
    drawerRef,
    filteredIds = [],
}: {
    onContactSelected: (contact: any) => void,
    onContactUnselected?: (contact: any) => void,
    drawerRef?: MutableRefObject<HTMLDivElement> | null,
    filteredIds?: string[],
}) {
    const { contacts, getContacts } = useContext(ContactsContext);

    // Contacts
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [baseContacts, setBaseContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [contactsAmount, setContactsAmount] = useState(100);

    // Tabs
    const [tabValue, setTabValue] = useState(1);
    const handleSetTabValue = (event: any, newValue: number) => setTabValue(newValue);

    // Search
    const [searchQuery, setSearchQuery] = useState("");
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();

    // Add Contact
    const [openCreateContactDrawer, setOpenCreateContactDrawer] = useState(false);
    const handleOpenCreateDrawer = () => setOpenCreateContactDrawer(true);
    const handleCloseCreateDrawer = () => setOpenCreateContactDrawer(false);

    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {
        if (drawerRef) {
            const drawerNode = drawerRef.current;
            if (drawerNode) {
                drawerNode.addEventListener('scroll', handleScroll);
            }

            // Clean up the event listener on unmount
            return () => {
                if (drawerNode) {
                    drawerNode.removeEventListener('scroll', handleScroll);
                }
            };
        }
    }, []);

    const handleScroll = () => {
        // console.log("1", Math.ceil(window.innerHeight + document.documentElement.scrollTop));
        // console.log("2", document.documentElement.offsetHeight);
        if (
            Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
            document.documentElement.offsetHeight
        ) {
            return;
        }


        setContactsAmount(contactsAmount + 100);
    };

    useEffect(() => {
        switch (tabValue) {
            case 1:
                // Recent Contacts
                let recent: any[] = [...contacts];
                recent.sort((a, b) => {
                    if (a?.lastInteraction && b?.lastInteraction && a?.lastInteraction instanceof Timestamp && b?.lastInteraction instanceof Timestamp) {
                        return b.lastInteraction.toMillis() - a.lastInteraction.toMillis();
                    }
                    return 0;
                });
                setBaseContacts(recent);
                break;
            case 2:
                // Priority Contacts
                let priority = contacts.filter((e) => e?.favorite || e?.favoriteByGoal);
                setBaseContacts(priority);
                break;
            default:
                // All Contacts
                setBaseContacts(contacts);
                break;
        }

    }, [contacts, tabValue]);

    useEffect(() => {
        setFilteredContacts(filterData(searchQuery, baseContacts).slice(0, contactsAmount));
    }, [searchQuery, baseContacts, contactsAmount]);

    const filterData = (query: string, data: any) => {
        if (!query) {
            return data.filter((e: any) => !filteredIds.includes(e.ref?.id));
        } else {
            return data.filter((e: any) => e?.name?.toLowerCase().includes(query.toLowerCase()) && !filteredIds.includes(e.ref?.id));
        }
    };

    const onContactTapped = (contact: any) => {
        let selected: any[] = [];
        if (selectedContacts.includes(contact)) {
            selected = [...selectedContacts];
            selected = selected.filter(e => e.ref?.id !== contact.ref?.id)
            onContactUnselected(contact);
        } else {
            selected = [...selectedContacts, contact];
            onContactSelected(contact)
        }

        setSelectedContacts(selected);
    }

    const onAddContactTapped = () => {
        let querySplit = searchQuery.split(" ");
        setFirstName(querySplit[0]);

        if (querySplit.length > 1) {
            setLastName(querySplit[1]);
        }

        return handleOpenCreateDrawer();
    };

    return (
        <MDBox mx={3}>
            <CreateContactDrawer
                openDrawer={openCreateContactDrawer}
                handleCloseDrawer={handleCloseCreateDrawer}
                enableNavigation={false}
                initialFirstName={firstName}
                initialLastName={lastName}
            />
            <OutlinedInput
                id="search-bar"
                className="text"
                onInput={(e) => {
                    setSearchQuery((e.target as HTMLInputElement).value);
                }}
                placeholder="Search relationships"
                fullWidth={true}
                endAdornment={<Search />}
            />
            <MDBox mt={3} />
            <Tabs
                orientation="horizontal"
                value={tabValue}
                onChange={handleSetTabValue}
            >
                <Tab
                    label="All"
                    {...a11yProps(0)} />
                <Tab
                    label="Recent"
                    {...a11yProps(1)} />
                <Tab
                    label="Priority"
                    {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
                {/* <AutoSizer>
                    {
                        ({ width, height }) => (<List
                            width={width}
                            height={height}
                            rowHeight={74}
                            rowRenderer={({ index, key, style }) => {
                                let contact = allContacts[index];
                                return (
                                    <ContactCard
                                        onClick={() => onContactTapped(contact)}
                                        contact={contact}
                                        isSelected={selectedContacts.includes(contact)}
                                        isFavoriteEnabled={false}
                                        enableScoreBadge={false}
                                        subtitle={contact.company} />
                                );
                            }}
                            rowCount={allContacts.length}
                            overscanRowCount={5} />
                        )
                    }
                </AutoSizer> */}
                {filteredContacts.map((contact: any) => (
                    <ContactCard
                        onClick={() => onContactTapped(contact)}
                        contact={contact}
                        isSelected={selectedContacts.includes(contact)}
                        isFavoriteEnabled={false}
                        enableScoreBadge={false}
                        subtitle={contact.company}
                    />
                ))}
                {filteredContacts.length === 0 && <>
                    <AddContactCard
                        onClick={onAddContactTapped}
                    />
                    <Stack m={"auto"} mt={"20vh"} direction="column" alignItems="center" spacing={0.5}>
                        <InfoOutlined style={{ color: "darkgray" }} />
                        <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>No relationships match your current query.</MDTypography>
                    </Stack>
                </>}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                {filteredContacts.map((contact: any) => (
                    <ContactCard
                        onClick={() => onContactTapped(contact)}
                        isSelected={selectedContacts.includes(contact)}
                        contact={contact}
                        isFavoriteEnabled={false}
                        enableScoreBadge={false}
                        subtitle={contact.company}
                    />
                ))}
                {filteredContacts.length === 0 && <>
                    <AddContactCard
                        onClick={onAddContactTapped}
                    />
                    <Stack m={"auto"} mt={"20vh"} direction="column" alignItems="center" spacing={0.5}>
                        <InfoOutlined style={{ color: "darkgray" }} />
                        <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>No relationships match your current query.</MDTypography>
                    </Stack>
                </>}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
                {filteredContacts.map((contact: any) => (
                    <ContactCard
                        onClick={() => onContactTapped(contact)}
                        contact={contact}
                        isSelected={selectedContacts.includes(contact)}
                        isFavoriteEnabled={false}
                        enableScoreBadge={false}
                        subtitle={contact.company}
                    />
                ))}
                {filteredContacts.length === 0 && <>
                    <AddContactCard
                        onClick={onAddContactTapped}
                    />
                    <Stack m={"auto"} mt={"20vh"} direction="column" alignItems="center" spacing={0.5}>
                        <InfoOutlined style={{ color: "darkgray" }} />
                        <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>No relationships match your current query.</MDTypography>
                    </Stack>
                </>}
            </CustomTabPanel>
        </MDBox>
    );
}

// Setting default values for the props of SelectContact
SelectContact.defaultProps = {
    onContactSelected: null,
};

export default SelectContact;