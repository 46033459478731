import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import validator from 'validator';

// MUI
import {
    Backdrop,
    Box,
    Checkbox,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    Icon,
    Stack,
    TextField,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import { InfoOutlined, PersonAddAlt1Outlined } from "@mui/icons-material";

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import ContactsApi from "../../services/api";
import { AuthContext, ContactsContext } from "context";
import { capitalize, debounce } from "lodash";
import { capitalizeAllWords } from "forge/core/utilities";
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { CustomWidthTooltip } from "forge/core/components/CustomWidthTooltip";
import { AbilityContext, Can } from "forge/organization/rbac/CanContext";
import { useAbility } from "@casl/react";
import { FirebaseError } from "firebase/app";
import ContactCard from "forge/core/components/ContactCard";
import theme from "assets/theme";

const styles = createUseStyles({
    checkbox: {
        "& .MuiTypography-body1": {
            width: "97%"
        }
    },
});

function CreateContactDrawer({
    openDrawer,
    handleCloseDrawer,
    enableNavigation = true,
    initialFirstName,
    initialLastName,
    initialEmail,
}: {
    openDrawer: boolean,
    handleCloseDrawer: () => void,
    enableNavigation?: boolean,
    initialFirstName?: string,
    initialLastName?: string,
    initialEmail?: string,
}): JSX.Element {
    // UI
    const classes = styles();

    // Navigation
    const navigate = useNavigate();
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    // Context 
    const { getCurrentUser } = useContext(AuthContext);
    const { commonEmailDomains } = useContext(RemoteConfigContext);
    const { getContact } = useContext(ContactsContext);
    const ability = useAbility(AbilityContext);

    // Services
    let { user, encryptionService, userProfileData, userRef, memberRef } = getCurrentUser();
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // State
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDomain, setLoadingDomain] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>(initialFirstName ?? "");
    const [lastName, setLastName] = useState<string>(initialLastName ?? "");
    const [company, setCompany] = useState<string>("");
    const [email, setEmail] = useState<string>(initialEmail ?? "");
    const [linkedInUrl, setLinkedInUrl] = useState<string>("");
    const [isOrganizationContact, setIsOrganizationContact] = useState<boolean>(ability.can("use", "organization"));

    const [errorMessage, setErrorMessage] = useState<string>();
    const [existingContact, setExistingContact] = useState<any>();

    useEffect(() => {
        setFirstName(initialFirstName ?? "");
        setLastName(initialLastName ?? "");
    }, [initialFirstName, initialLastName]);

    const extractDataFromEmail = (email: string): void => {
        const emailWithoutDomain: string = email.substring(0, email.lastIndexOf("@"));
        const names: string[] = emailWithoutDomain.split(/\.|-|_/);

        if (names.length > 0) {
            if (names.length > 1) {
                // Last Name
                if (!lastName.trim()) setLastName(capitalize(names.pop()?.trim()));

                // First Name(s)
                if (!firstName.trim()) setFirstName(capitalizeAllWords(names.join(" ")));
            } else {
                if (!firstName.trim()) setFirstName(capitalize(names[0].trim()));

            }
        }

        const domain: string = email.substring(email.lastIndexOf("@") + 1, email.length);
        const secondLevelDomain: string = domain.substring(0, domain.indexOf("."));
        const isCommonEmailDomain = !commonEmailDomains.some((item) => item.includes(secondLevelDomain));

        if (!commonEmailDomains.some((item) => item.includes(secondLevelDomain))) {
            if (!company.trim()) setCompany(capitalize(secondLevelDomain.trim()));

            const debouncedFunction = debounce(
                async () => {
                    setLoadingDomain(true);
                    let value = await contactsApi.getCompanyFromDomain(domain, email);
                    if (value !== null) {
                        if (value.firstName !== null) {
                            if (!firstName.trim()) setFirstName(value.firstName);
                        }

                        if (value.lastName !== null) {
                            if (!lastName.trim()) setLastName(value.lastName);
                        }

                        if (!company.trim()) setCompany(value.company?.name);
                    }
                    setLoadingDomain(false);
                },
                300
            );

            debouncedFunction();

        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);

        try {
            const data: any = {
                firstName,
                lastName,
                name: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
                company,
                linkedInUrl,
                status: "live",
                createdBy: (memberRef ?? userRef).path,
            };

            if (email) {
                data.emails = [{
                    'fieldType': "email",
                    'type': "work",
                    'value': email,
                    'favorite': true,
                    'favoriteType': undefined,
                    'sourceValue': undefined,
                }];
            }

            let response = await contactsApi.createContact(data, isOrganizationContact);
            setLoading(false);

            if (response?.data?.contactId && enableNavigation) {
                navigate(`/people/relationships/${response?.data?.contactId}`);
            }
            handleCloseDrawer();
        } catch (error: any) {
            console.warn(error);
            if (error instanceof FirebaseError) {
                const response = JSON.parse(error.message);

                if (response.status && response.status === "ALREADY_EXISTS") {
                    setErrorMessage(response.message);
                    setExistingContact(getContact(response.details));
                }
            }
            setLoading(false);
        }
    };

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleCloseDrawer}
            PaperProps={{
                sx: {
                    height: "auto",
                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                },
            }}
        >
            <Backdrop
                sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                open={loading}
            >
                <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
            </Backdrop>

            <MDBox display="flex" alignItems="baseline" pt={4} pb={0.5} px={3}>
                <PersonAddAlt1Outlined
                    sx={{
                        transform: "translateY(4px)",
                    }}
                />
                <MDBox ml={1}>
                    <MDTypography variant="h5">Add a New Contact</MDTypography>
                </MDBox>
                <Box style={{ flex: 1 }} />
                <Icon
                    sx={{
                        cursor: "pointer",
                        transform: "translateY(4px)",
                    }}
                    onClick={handleCloseDrawer}
                >
                    close
                </Icon>
            </MDBox>

            <Divider />

            <FormControl component="form" onSubmit={handleSubmit} style={{ width: "100%" }}>
                <MDBox px={3}>
                    <MDBox sx={{ display: "flex", alignItems: "flex-end" }} mb={1.5}>
                        <TextField
                            id="email"
                            label="Email"
                            variant="outlined"
                            style={{ flex: 1 }}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (validator.isEmail(e.target.value)) {
                                    extractDataFromEmail(e.target.value);
                                }
                                return;
                            }}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            InputProps={{
                                endAdornment: loadingDomain ? (<img
                                    src={spinningSphere}
                                    alt="loading"
                                    style={{ height: "44px" }}
                                />)
                                    : (<></>)
                            }}
                        />
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "flex-end" }} mb={1.5}>
                        <TextField
                            id="linkedin"
                            label="LinkedIn Url"
                            variant="outlined"
                            style={{ flex: 1 }}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            value={linkedInUrl}
                            onChange={(e) => setLinkedInUrl(e.target.value)}
                        />
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "flex-end" }} mb={1.5}>
                        <Divider style={{ flex: 1 }} />
                        <MDTypography variant="body2" color="text">
                            OR
                        </MDTypography>
                        <Divider style={{ flex: 1 }} />
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "flex-end" }} mb={1.5}>
                        <TextField
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            style={{ flex: 1 }}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            value={firstName ? firstName : ''}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <Box style={{ width: "1em" }} />
                        <TextField
                            id="lastName"
                            label="Last Name"
                            variant="outlined"
                            style={{ flex: 1 }}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            value={lastName ? lastName : ''}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "flex-end" }} mb={1.5}>
                        <TextField
                            id="company"
                            label="Organization"
                            variant="outlined"
                            style={{ flex: 1 }}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            value={company ? company : ''}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </MDBox>
                    <Can I="use" an="organization">
                        <FormControlLabel
                            className={classes.checkbox}
                            control={<Checkbox
                                checked={isOrganizationContact}
                                onChange={(_, checked) => setIsOrganizationContact(checked)}
                            />}
                            style={{ paddingBottom: "8px" }}
                            label={<Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                                <MDTypography variant="body2" fontWeight="bold">Share contact with my teams</MDTypography>
                                <CustomWidthTooltip
                                    title="A team contact will be shared with your organization where you can collaborate with your colleagues, still you will be able to save private info without sharing. If unselected, the contact and all its data will be private for you."
                                >
                                    <InfoOutlined />
                                </CustomWidthTooltip>
                            </Stack>
                            } />
                    </Can>
                    {errorMessage && <>
                        <Divider style={{ flex: 1 }} />
                        <MDTypography variant="body2" style={{ textAlign: "center", color: "red", marginBottom: "12px" }}>{errorMessage}</MDTypography>
                        {existingContact && <ContactCard
                            contact={existingContact}
                            isFavoriteEnabled={false}
                            enableScoreBadge={false}
                            onClick={() => {
                                navigate(`/people/relationships/${existingContact.ref?.id}`);
                                handleCloseDrawer();
                            }}
                        />}
                    </>}
                </MDBox>
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        p: 1.5,
                        pb: 2,
                        borderTop: "1px solid",
                        borderColor: "divider",
                        justifyContent: "space-between",
                    }}
                >
                    <MDButton variant="text" color="dark" onClick={() => { }}>
                        Clear
                    </MDButton>
                    <Stack direction="row" spacing={1}>
                        <MDButton variant="gradient" color="info" type="submit" onClick={(e) => e.stopPropagation()}>
                            Create Contact
                        </MDButton>
                    </Stack>
                </Box>
            </FormControl>
        </Drawer>
    );
}

// Setting default values for the props of CreateContact
CreateContactDrawer.defaultProps = {
    handleCloseDrawer: null,
};

// Typechecking props for the CreateContact
CreateContactDrawer.propTypes = {
    handleCloseDrawer: PropTypes.func.isRequired,
};

export default CreateContactDrawer;