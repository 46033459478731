import { useContext, useEffect } from "react";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import firebase from "firebase/compat/app";
import { auth } from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { AuthContext } from "context";

// Image
import bgImage from "assets/forge/images/forge.png";
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { createUseStyles } from "react-jss";
import SignInButton from "../components/SignInButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

const styles = createUseStyles({
  ssoButtons: {
    "& .firebaseui-idp-button": {
      background: "white !important",
      borderRadius: "8px"
    },
    "& .firebaseui-idp-text": {
      color: "#757575 !important"
    },
    "& .firebaseui-idp-icon": {
      filter: "brightness(0) !important"
    }
  },
});

function SignIn(): JSX.Element {
  // UI
  const classes = styles();

  // Context
  const { login, loggingIn, microsoftSso } = useContext(AuthContext);

  // State
  useEffect(() => {
    var ui = auth.AuthUI.getInstance() || new auth.AuthUI(firebase.auth());
    try {
      ui.start("#firebaseui-auth-container", {
        signInFlow: "popup",
        // signInSuccessUrl: "/home",
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, _) {
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect
            // automatically or whether we leave that to developer to handle.
            login();
            return false;
          },
          signInFailure: function (error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            console.log(JSON.stringify(error));
            return;
          },
          uiShown: function () {
            // The widget is rendered.
          },
        },
        signInOptions: [
          // List of OAuth providers supported.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: ['email', 'profile'],
          },
          "apple.com",
        ],
      });
    } catch (error) {
      console.warn(error);
    }
  });

  return (
    <IllustrationLayout
      title="Sign In"
      description="Select a provider below to sign in"
      illustration={bgImage}
      testimonials={false}
    >
      {loggingIn
        ? <img src={spinningSphere} style={{ width: "256px", margin: "0 auto", display: "block" }} alt="Loading..." />
        : <div>
          <div id="firebaseui-auth-container" className={classes.ssoButtons}></div>
          <SignInButton
            ssoOption="microsoft"
            cta="Sign in"
            onClick={() => microsoftSso()}
          />
        </div>
      }
      <MDBox mt={7} mb={1} textAlign="center">
        <MDTypography variant="button" color="text">
          Don't have an account yet?{" "}
          <MDTypography
            component={Link}
            to="/create-account"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Sign Up
          </MDTypography>
        </MDTypography>
      </MDBox>
    </IllustrationLayout >
  );
}

export default SignIn;
