import { capitalize } from "@mui/material";
import MDTypography from "components/MDTypography";
import { escapeRegExp } from "lodash";

export const parseKnowledge = (knowledge: any, answer?: string, defaultFontWeight: string = "normal") => {
    if (!knowledge) {
        return <MDTypography display='inline' variant="body2" fontWeight={defaultFontWeight}>{answer}</MDTypography>;
    }

    if (!(knowledge.answer || answer)) {
        return <MDTypography display='inline' variant="body2" fontWeight={defaultFontWeight}>{answer}</MDTypography>;
    }

    // Remove Curly Braces, Parenthesis, Brackets
    let text = (answer ?? knowledge.answer)?.replace(/{|}|\[|\]|\(|\)/g, '');

    // Separate text into parts based on keywords
    let result = separateText(
        text,
        [
            ...(knowledge?.entity ?? []),
            ...(knowledge?.searchTerm ?? []),
            ...(knowledge.subjects ?? []),
            ...(knowledge.milestone?.triggerTexts ?? [])
        ]
    );

    return result.map<JSX.Element>((part: string) => {
        if (knowledge.entity && knowledge.entity?.some((entity: string) => entity.toLowerCase() === part.toLowerCase())) {
            return <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "cornflowerblue", textDecoration: "underline", cursor: "pointer" }}>{part}</MDTypography>
        }

        if (knowledge.searchTerm && knowledge.searchTerm?.some((searchTerm: string) => searchTerm.toLowerCase() === part.toLowerCase())) {
            return <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "cornflowerblue" }}>{part}</MDTypography>
        }

        if (knowledge.subjects && knowledge.subjects?.some((subject: string) => subject.toLowerCase() === part.toLowerCase())) {
            return <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "orange" }}>{capitalize(part)}</MDTypography>
        }

        if (knowledge.milestone?.triggerTexts && knowledge.milestone?.triggerTexts?.some((triggerText: string) => triggerText.toLowerCase() === part.toLowerCase())) {
            return <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "green" }}>{capitalize(part)}</MDTypography>
        }

        // Regular expression to find URLs in the text
        const urlPattern = /(\bhttps?:\/\/[^\s]+)/g;

        // Replace the URL in the string with an anchor tag
        const linkedText = part.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
        return <MDTypography
            display='inline'
            variant="body2"
            fontWeight={defaultFontWeight}
            dangerouslySetInnerHTML={{
                __html: linkedText,
            }}></MDTypography>
    });
}

export const parseMilestone = (milestone: any) => {
    if (!milestone.name) {
        return null;
    }

    // Remove Curly Braces
    let text = milestone.name.replace(/{|}/g, '').replace(/\[\[|\]\]|\[\(|\)\]/g, '');

    // Separate text into parts based on keywords
    let result = separateText(text, [milestone.subject, ...(milestone?.triggerTexts ?? [])]);

    return result.map<JSX.Element>((part: string) => {

        if (milestone.subject && milestone.subject == part) {
            return <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "orange" }}>{capitalize(part)}</MDTypography>
        }

        if (milestone?.triggerTexts && milestone?.triggerTexts.includes(part)) {
            return <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "green" }}>{capitalize(part)}</MDTypography>
        }

        return <MDTypography display='inline' variant="body2">{part}</MDTypography>
    });
}

function separateText(text: string, keywords: string[]) {
    // Initialize an array to store the separated parts
    let parts = [];

    // Remove commas at the end
    text = text.replace(/,(?=\s*$)/g, '');

    // Remove empty strings from keywords
    keywords = keywords.filter(item => item !== '');

    if (text) {
        // Initialize a variable to store the current part
        let currentPart = '';

        // Iterate through each character in the text
        for (let i = 0; i < text.length; i++) {
            const char = text[i];
            const nextChar = text[i + 1] || '';

            // Add the current character to the current part
            currentPart += char;

            // Check if the current part contains any of the keywords
            const matchedKeyword = keywords.find(keyword => {
                let regExp = new RegExp(`\\b${escapeRegExp(keyword)}(?:'s)?\\b`, 'gmi')
                return regExp.test(currentPart + nextChar);
            });

            // If the current part contains a keyword, add it to the parts array
            if (matchedKeyword) {
                try {
                    let regExp = new RegExp(`\\b${escapeRegExp(matchedKeyword)}(?:'s)?\\b`, 'gmi')

                    if (regExp.test(currentPart)) {
                        currentPart.replace(regExp, (match) => {
                            parts.push(currentPart.slice(0, currentPart.indexOf(match)));
                            parts.push(match);
                            return '';
                        });
                        currentPart = "";
                    }
                } catch (error) {
                    console.warn(error);
                }
            }

        }

        // Add the remaining part to the parts array if not empty
        if (currentPart.trim() !== '') {
            parts.push(currentPart);
        }
    }

    return parts;
}
