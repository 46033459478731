import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "assets/theme";

// Context
import { AuthContext, ContactsContext } from "context";

// @mui material components
import {
    Drawer,
    useMediaQuery,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Board from "@asseinfo/react-kanban";

// Components
import AddKnowledgeDrawer from "../../../knowledge/components/AddKnowledgeDrawer";

import ContactHeaderCard from "../components/ContactHeaderCard";
import ContactResearchCard from "../components/ContactResearchCard";
import ContactRecallCard from "../components/ContactRecallCard";
import KnowledgeDetail from "../../../knowledge/components/KnowledgeDetail";
import { NewsContextProvider } from "forge/outreach/news/services/NewsContext";
import { createUseStyles } from "react-jss";
import ContactNewsCard from "../components/ContactNewsCard";
import ContactCommitmentsCard from "../components/ContactCommitmentsCard";
import { CommitmentsContextProvider } from "../../../commitments/services/CommitmentsContext";
import { MilestonesContextProvider } from "../services/MilestonesContext";
import ContactMilestonesCard from "../components/ContactMilestonesCard";
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer";
import ContactListenCard from "../components/ContactListenCard";
import { ListenContextProvider } from "../services/ListenContext";
import { LinkedInProfileContextProvider } from "../services/LinkedInProfileContext";
import { KnowledgeContextProvider } from "forge/knowledge/services/KnowledgeContext";
import { ContactContextProvider } from "../services/ContactContext";
import { Timestamp } from "firebase/firestore";
import ContactsApi from "../services/api";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";
import ContactCallsCard from "../components/ContactCallsCard";
import { CallsContextProvider } from "forge/pipeline/calls/services/CallsContext";
import CreateCallDrawer from "forge/pipeline/calls/components/CreateCallDrawer";

const boards = {
    columns: [
        {
            id: "research",
            cards: [
                {
                    id: "researchCard",
                },
            ],
        },
        {
            id: "listen",
            cards: [
                {
                    id: "listenCard",
                },
            ],
        },
        ,
        {
            id: "recall",
            cards: [
                {
                    id: "recallCard",
                },
            ],
        },
        {
            id: "commitments",
            cards: [
                {
                    id: "commitmentsCard",
                },
            ],
        },
        {
            id: "calls",
            cards: [
                {
                    id: "callsCard",
                },
            ],
        },
        {
            id: "news",
            cards: [
                {
                    id: "newsCard",
                },
            ],
        },
        {
            id: "milestones",
            cards: [
                {
                    id: "milestonesCard",
                },
            ],
        },
    ]
};

const styles = createUseStyles({
    root: {
        overflowX: 'auto', // Enable horizontal scrolling
        '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar
        },
        '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
    },
    card: {
        minWidth: 300, // Minimum width for cards
        marginRight: theme.spacing(2), // Spacing between cards
    },
});

const ContactOverview = ({ contactId }: { contactId?: string }) => {
    const classes = styles();
    const { id } = useParams();
    const { getCurrentUser } = useContext(AuthContext);
    const { contactsMap, getContact } = useContext(ContactsContext);

    // State
    const [contact, setContact] = useState<any>({});
    const [openNameDialog, setOpenNameDialog] = useState<boolean>(false);

    // Services
    let { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // Add Knowledge
    const [openKnowledgeDrawer, setOpenKnowledgeDrawer] = useState(false);
    const handleOpenDrawer = () => setOpenKnowledgeDrawer(true);
    const handleCloseDrawer = () => setOpenKnowledgeDrawer(false);

    // Add Commitment
    const [openCommitmentDrawer, setOpenCommitmentDrawer] = useState(false);
    const handleOpenCommitmentDrawer = () => setOpenCommitmentDrawer(true);
    const handleCloseCommitmentDrawer = () => setOpenCommitmentDrawer(false);

    // Add Call
    const [openCallDrawer, setOpenCallDrawer] = useState(false);
    const handleOpenCallDrawer = () => setOpenCallDrawer(true);
    const handleCloseCallDrawer = () => setOpenCallDrawer(false);

    // Knowledge Detail
    const [knowledge, setKnowledge] = useState<any>();
    const [linkedInData, setLinkedInData] = useState<boolean>(false);
    const [openKnowledgeDetailDrawer, setOpenKnowledgeDetailDrawer] = useState(false);
    const handleOpenDetailDrawer = (knowledge: any, linkedInData?: boolean) => {
        setKnowledge(knowledge);
        setLinkedInData(linkedInData)
        setOpenKnowledgeDetailDrawer(true);
    }
    const handleCloseDetailDrawer = () => setOpenKnowledgeDetailDrawer(false);
    // Reference to the board container
    const boardContainerRef = useRef(null);

    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {
        if (!id && !contactId) return;

        const response = getContact(contactId ?? id);
        setContact(response);
    }, [contactsMap]);

    useEffect(() => {
        if (!id && !contactId) return;

        const response = getContact(contactId ?? id);
        setContact(response);

        if (response && response.relationshipDataTimeDecayUpdatedAt && response.relationshipDataTimeDecayUpdatedAt instanceof Timestamp) {
            // Get the current date
            const currentDate = new Date();

            // Subtract 30 days from the current date
            const comparisonDate = new Date();
            comparisonDate.setDate(currentDate.getDate() - 30);
            const relationshipDataTimeDecayUpdatedAt = response.relationshipDataTimeDecayUpdatedAt.toDate();

            if (relationshipDataTimeDecayUpdatedAt < comparisonDate) {
                contactsApi.updateRelationshipData(response);
            }
        }
    }, [contactId ?? id]);

    useEffect(() => {
        if (!contact) return;

        console.log(contact);
        console.log(contact.linkedInProfileData);
        var firstName = contact.linkedInProfileData?.first_name;
        var lastName = contact.linkedInProfileData?.last_name;
        var differentFirstName = (firstName != null && contact.firstName != firstName);
        var differentLastName = (lastName != null && contact.lastName != lastName);

        if ((differentFirstName || differentLastName) &&
            !(contact.linkedInProfileData?.differentNameFlag ?? false)) {
            setOpenNameDialog(true);
        }
    }, [contact]);

    const renderCard = (id: string) => {
        switch (id) {
            case 'researchCard':
                return <ContactResearchCard
                    contact={contact}
                    handleOpenDetailDrawer={(knowledge: any, linkedInData?: any) => handleOpenDetailDrawer(knowledge, true)}
                />;
            case 'listenCard':
                return <ContactListenCard contact={contact} />
            case 'recallCard':
                return <ContactRecallCard
                    contact={contact}
                    handleOpenDrawer={handleOpenDrawer}
                    handleOpenDetailDrawer={handleOpenDetailDrawer}
                />;
            case 'newsCard':
                return <ContactNewsCard
                    handleOpenDrawer={handleOpenDrawer}
                />;
            case 'commitmentsCard':
                return <ContactCommitmentsCard
                    contact={contact}
                    handleOpenDrawer={handleOpenCommitmentDrawer}
                />;
            case 'milestonesCard':
                return <ContactMilestonesCard
                    contact={contact}
                    handleOpenDrawer={handleOpenDrawer}
                />;
            case 'callsCard':
                return <ContactCallsCard
                    contact={contact}
                    handleOpenDrawer={handleOpenCallDrawer}
                />;
            default:
                return <></>;
        }
    }

    const handleCloseDialogName = async (result: boolean) => {
        setOpenNameDialog(false);
        if (result) {
            var firstName = contact?.linkedInProfileData?.first_name ?? contact?.firstName ?? '';
            var lastName = contact?.linkedInProfileData?.last_name ?? contact?.lastName ?? '';
            const name = `${firstName ?? ""} ${lastName ?? ""}`.trim();

            await contactsApi.updateContact({
                ref: contact?.ref,
                firstName,
                lastName,
                name,
            });
        } else {
            await contactsApi.updateContact({
                ref: contact?.ref,
                "linkedInProfileData.differentNameFlag": true,
            });
        }
    };

    // Scroll to left
    const scrollToLeft = () => {
        if (boardContainerRef.current) {
            boardContainerRef.current.scrollLeft = 0;
        }
    };

    // Scroll to right
    const scrollToRight = () => {
        if (boardContainerRef.current) {
            boardContainerRef.current.scrollLeft = boardContainerRef.current.scrollWidth;
        }
    };

    return (
        <ContactContextProvider contact={contact}>
            <KnowledgeContextProvider contact={contact}>
                <CommitmentsContextProvider contact={contact}>
                    <MilestonesContextProvider contact={contact}>
                        <NewsContextProvider contact={contact}>
                            <ListenContextProvider contact={contact}>
                                <CallsContextProvider contact={contact}>
                                    <LinkedInProfileContextProvider contact={contact}>
                                        <ConfirmationDialog
                                            openDialog={openNameDialog}
                                            handleCloseDialog={handleCloseDialogName}
                                            title="Do you want to update this person's name?"
                                            description={
                                                <Fragment>
                                                    LinkedIn has a different spelling.
                                                    <br />
                                                    {`First Name: ${contact?.linkedInProfileData?.first_name}`}
                                                    <br />
                                                    {`Last Name: ${contact?.linkedInProfileData?.last_name}`}
                                                </Fragment>
                                            }
                                            denyText="Ignore"
                                            confirmText="Update"
                                            denyVariant="outlined"
                                            confirmColor="info"
                                        />
                                        <AddKnowledgeDrawer
                                            openDrawer={openKnowledgeDrawer}
                                            handleCloseDrawer={handleCloseDrawer}
                                            contact={contact}
                                            keepSelectedContact={true}
                                        />
                                        <Drawer
                                            anchor="right"
                                            open={openKnowledgeDetailDrawer}
                                            onClose={handleCloseDetailDrawer}
                                            PaperProps={{
                                                sx: {
                                                    height: "auto",
                                                    maxHeight: "96%",
                                                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                                                },
                                            }}
                                        >
                                            <KnowledgeDetail handleCloseDrawer={handleCloseDetailDrawer} knowledge={knowledge} displayKnowledge={!linkedInData} />
                                        </Drawer>
                                        <CreateCommitmentDrawer
                                            openDrawer={openCommitmentDrawer}
                                            handleCloseDrawer={handleCloseCommitmentDrawer}
                                            contact={contact}
                                            keepSelectedContact={true}
                                        />
                                        <CreateCallDrawer
                                            openDrawer={openCallDrawer}
                                            handleCloseDrawer={handleCloseCallDrawer}
                                            contact={contact}
                                        />

                                        <MDBox mb={3}>
                                            <ContactHeaderCard contact={contact} isContactProfilePage={contactId === null || contactId === undefined} />
                                            {/* <button onClick={scrollToLeft}>Scroll to Left</button>
                                        <button onClick={scrollToRight}>Scroll to Right</button>
                                        <div style={{ width: '100%', height: '500px', overflowX: 'auto' }} ref={boardContainerRef}> */}
                                            <Board
                                                initialBoard={boards}
                                                disableCardDrag={true}
                                                renderColumnHeader={({ id, title }: any, { addCard }: any) => (<></>)}
                                                renderCard={({ id, template }: any, { dragging }: any) => (
                                                    <MDBox
                                                        key={id}
                                                        dragging={dragging.toString() || undefined}
                                                        display="block"
                                                        width="calc(510px - 40px)"
                                                        color="text"
                                                        borderRadius="xl"
                                                        mt={2.55}
                                                        mr={3}
                                                        pb={1}
                                                        sx={{ height: "100%" }}
                                                    >
                                                        {renderCard(id)}
                                                    </MDBox>
                                                )}
                                            />
                                            {/* </div> */}
                                        </MDBox>
                                        {!contactId && <Footer />}
                                    </LinkedInProfileContextProvider>
                                </CallsContextProvider>
                            </ListenContextProvider>
                        </NewsContextProvider>
                    </MilestonesContextProvider>
                </CommitmentsContextProvider>
            </KnowledgeContextProvider >
        </ContactContextProvider >
    );
};

export default ContactOverview;