import { Star } from "@mui/icons-material";
import { Avatar, Stack, useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import MDTypography from "components/MDTypography";

const Review = ({
    review,
    image,
    author,
    role,
}: {
    review: string
    image: string
    author: string
    role: string
}) => {
    const xlargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
    
    return <Stack alignItems="center" spacing={2} style={{ margin: 0, position: "absolute", top: xlargeScreen ? "60%" : "50%" }}>
        <Stack direction="row" spacing={1}>
            <Star color="warning" fontSize="large" />
            <Star color="warning" fontSize="large" />
            <Star color="warning" fontSize="large" />
            <Star color="warning" fontSize="large" />
            <Star color="warning" fontSize="large" />
        </Stack>
        <MDTypography variant="body2" fontWeight="500" textAlign="center" style={{ color: "white" }}>
            {review}
        </MDTypography>
        <Avatar
            src={image}
            sx={{ height: 60, width: 60 }}
        />
        <div>
            <MDTypography variant="body2" fontWeight="500" textAlign="center" style={{ color: "white" }}>
                {author}
            </MDTypography>
            <MDTypography variant="body2" fontWeight="500" textAlign="center" style={{ color: "white" }}>
                {role}
            </MDTypography>
        </div>
    </Stack>;
};

export default Review;