import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import MDButton from 'components/MDButton';
import { DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';

function AddContactDetailDialog({
    type,
    open,
    confirmationText = "Continue",
    onContinue,
    onClose,
}: {
    type: string,
    open: boolean,
    confirmationText?: string,
    onContinue: (email: string) => void
    onClose?: () => void
}) {
    const [value, setValue] = useState<string>();
    const [types, setTypes] = useState<any>(() => {
        switch (type) {
            case "email":
                return {
                    headline: "emails",
                    label: "Email",
                    type: "email",
                };
            case "phone":
                return {
                    headline: "phone numbers",
                    label: "Phone Number",
                    type: "tel",
                };
            default:
                return "";
        }
    });

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: { borderRadius: "16px" },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    It seems like this contact has no {types.headline} attached, do you want to add one?
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="key"
                        name="key"
                        label={types.label}
                        type={types.type}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            style: { color: 'gray' },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <MDButton variant="outlined" onClick={() => onClose && onClose()} color={"info"}>
                        Cancel
                    </MDButton>
                    <MDButton onClick={() => onContinue(value)} color={"info"}>
                        {confirmationText ?? "Confirm"}
                    </MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default AddContactDetailDialog;